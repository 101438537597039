import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { lang } from "@utils/helpers";

import CasePoints from "@components/cases/template/points";
import Letters from "@components/svg/letters";

const Wrapper = tw.div`
  relative flex flex-col mx-40 mb-40
  md:mb-120
  lg:flex-row lg:mx-0 lg:mb-160
`;

const Header = tw.div`
  mb-20 text-md
  md:text-xl
  lg:relative lg:mr-160 lg:mb-0 lg:text-38 lg:leading-60
  xl:mr-178 xl:whitespace-no-wrap
`;

const Mask = styled(motion.span)`
  ${tw`hidden lg:block lg:absolute lg:inset-0 lg:bg-white`}
`;

const LettersWrapper = tw.div`
  hidden
  lg:block lg:absolute lg:bottom-80 lg:-left-180 lg:w-430
  xl:bottom-95 xl:-left-95
`;

const CaseOffer = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 100, sectionStart + 450], [0, 340]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Header>
        { data.text[lang] }
        <Mask style={ width >= 1024 && { left }} />
      </Header>
      <CasePoints
        width={width}
        scroll={scroll} 
        sectionStart={sectionStart}
        data={data.points}
      />
      <LettersWrapper>
        <Letters />
      </LettersWrapper>
    </Wrapper>
  );
};

export default CaseOffer;
