import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { lang } from "@utils/helpers";

import ArrowPrimary from "@components/arrows/primary";

const Wrapper = tw.div`relative`;

const Description = tw(motion.h2)`
  max-w-260 mx-40 mb-40 text-second text-sm leading-20 -tracking-021
  lg:max-w-450 lg:mb-80 lg:text-md lg:leading-30 lg:-tracking-024
`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden transform
    md:block md:absolute md:bottom-20 md:right-40 md:w-100
    lg:bottom-0 lg:right-20 lg:w-130
  `}
  
  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const CaseDescription = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [-120, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Description style={ width >= 1024 && { x, opacity }}>
        { data.text[lang] }
      </Description>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default CaseDescription;
