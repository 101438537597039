import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import Img from "gatsby-image";

const Wrapper = tw.div`
  relative flex mb-80
  sm:mt-8
  lg:mb-180
  xl:mb-300
`;

const Logo = tw(motion.div)`
  absolute top-110 -left-40 w-240
  sm:top-1/2 sm:left-40 sm:transform sm:-translate-y-1/2
  md:left-80
  lg:left-120 lg:w-360
  xl:w-400 xl:left-185
`;

const ColorBox = tw.div`
  relative -top-50 left-80 h-300 w-full bg-lightRed -z-1
  sm:top-0 sm:left-1/2
  lg:h-460 lg:z-1
`;

const Illustration = tw(motion.div)`
  absolute! top-160 left-120 w-150
  sm:top-80 sm:left-20 sm:w-200
  md:left-120
  lg:w-310
  xl:top-115 xl:left-150
`;

const CaseLogo = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "illustration-blue.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const scale = useTransform(scroll, [sectionStart + 230, sectionStart + 620], [0, 1]);
  const rotate = useTransform(scroll, [sectionStart + 230, sectionStart + 620], [-8, 0]);
  const x = useTransform(scroll, [sectionStart + 100, sectionStart + 1600], [500, -300]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <ColorBox>
        <Logo style={ width >= 1024 && { scale, rotate, y: '-50%' }}>
          <Img fluid={ data.image.asset.fluid } />
        </Logo>
      </ColorBox>
      <Illustration style={ width >= 1024 && { x }}>
        <Img fluid={ illustration.childImageSharp.fluid } />
      </Illustration>
    </Wrapper>
  );
};

export default CaseLogo;
