import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { lang } from "@utils/helpers";

type PointsProps = {
  width: number,
  scroll: any,
  sectionStart: number,
  data: any
};

const PointsWrapper = tw.div`
  flex flex-col flex-wrap
  md:flex-row md:justify-between
`;

const PointWrapper = tw.div`
  max-w-300 mb-25
  md:mb-30
  lg:mb-80
  xl:w-360 xl:max-w-none xl:mb-45
`;

const Point = tw(motion.div)`
  text-second
  lg:relative lg:leading-25 lg:-tracking-024
  xl:h-125 xl:pr-30 xl:whitespace-pre-line
`;

const PointHeading = tw(motion.div)`
  font-pangramBold mb-10 text-sm text-portfolioText
  lg:mb-16 lg:text-lg lg:leading-32 lg:-tracking-03
`;

const CasePoints = ({ width, scroll, sectionStart, data }: PointsProps) => {
  const { t } = useTranslation();

  const opacity = useTransform(scroll, [sectionStart + 100, sectionStart + 400], [0, 1]);
  const opacityDelay = useTransform(scroll, [sectionStart + 120, sectionStart + 450], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 120, sectionStart + 450], [120, 0]);

  const opacity2 = useTransform(scroll, [sectionStart + 320, sectionStart + 620], [0, 1]);
  const opacityDelay2 = useTransform(scroll, [sectionStart + 340, sectionStart + 670], [0, 1]);
  const left2 = useTransform(scroll, [sectionStart + 340, sectionStart + 670], [120, 0]);

  return (
    <PointsWrapper>
      { data.map((point, index) => (
        <PointWrapper key={index}>
          <PointHeading style={ width >= 1024 && { opacity: index < 1 ? opacity : opacity2 }}>
            { point.name[lang] }
          </PointHeading>
          <Point style={ width >= 1024 && {
            left: index ? left2 : left,
            opacity: index ? opacityDelay2 : opacityDelay
          }}>
            { point.description[lang] }
          </Point>
        </PointWrapper>
      ))}
    </PointsWrapper>
  );
};

export default CasePoints;
