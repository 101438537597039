import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { fullWidthBox } from "@utils/styled-components";
import { motion, useTransform } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary";
import ArrowSecondary from "@components/arrows/secondary";

const Background = tw(fullWidthBox)`
  h-250 my-60 bg-lightOrange
  sm:h-280
  md:h-400
  lg:h-700 lg:mb-110
`;

const Mockup = tw(motion.div)`
  relative -top-30 left-230 w-480 transform
  sm:left-380
  md:-top-70 md:left-300 md:w-750
  lg:-top-90 lg:left-1/2 lg:w-1200 lg:-translate-x-1/2
`;

const ArrowDefault = styled.div`
  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const ArrowWrapper = tw(ArrowDefault)`
  hidden
  sm:block sm:absolute sm:-top-70 sm:left-880 sm:w-120
  md:left-1050
  lg:-top-140 lg:left-1520 lg:w-220
`;

const ArrowWrapperSecond = tw(ArrowDefault)`
  hidden transform -rotate-18
  sm:block sm:absolute sm:top-40 sm:left-300 sm:w-80
  md:top-80 md:left-250
  lg:top-160 lg:left-240 lg:w-120
`;

const CaseMockup = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart + 180, sectionStart + 560], [200, 0]);
  const opacity = useTransform(scroll, [sectionStart + 180, sectionStart + 560], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Background ref={Ref}>
      <Mockup style={ width >= 1024 && { y, x: width >= 1280 ? '-50%' : '-62%', opacity }}>
        <Img fluid={data.image.asset.fluid} />
      </Mockup>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
      <ArrowWrapperSecond>
        <ArrowSecondary />
      </ArrowWrapperSecond>
    </Background>
  );
};

export default CaseMockup;
