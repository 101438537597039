import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import tw from "twin.macro";
import Img from "gatsby-image";

const Wrapper = tw.div`flex mb-40 md:mb-80 lg:mb-200`;

const Extras = tw(motion.div)`
  hidden
  md:block md:w-360 md:mr-30
  lg:w-620
`;

const Cover = tw(motion.div)`
  w-full h-240
  sm:h-380
  md:h-auto
`;

const IFrame = tw.iframe`w-full h-full`;

const CaseVideo = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const isVideo = data.right[0]._type === 'url_obj';
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 100, sectionStart + 550], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 100, sectionStart + 550], [-120, 0]);
  const xLeft = useTransform(scroll, [sectionStart + 100, sectionStart + 550], [120, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Extras style={ width >= 1024 && { opacity, x }}>
        <Img fluid={data.left.asset.fluid} />
      </Extras>
      <Cover style={ width >= 1024 && { opacity, x: xLeft }}>
        { 
        isVideo ? (
          <IFrame
            src={`https://www.youtube.com/embed/${data.right[0].id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            />
        ) : (
          <Img fluid={data.right[0].asset.fluid} />
        )}
      </Cover>
    </Wrapper>
  );
};

export default CaseVideo;
