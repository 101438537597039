import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import Img from "gatsby-image";

const MockupWrapper = tw(motion.div)`
  relative mb-120
  sm:mb-200
  lg:w-755 lg:ml-auto lg:mb-110
  xl:mb-90
`;

const Mockup = tw(motion.div)`
  absolute! -bottom-1/2 -right-10 w-1/2
  lg:-bottom-250 lg:-right-205 lg:w-620
`;

const IllustrationWrapper = tw(motion.div)`
  relative mb-100
  sm:mb-100
  lg:w-700 lg:mb-180
  xl:w-755
`;

const Illustration = tw(motion.div)`
  absolute! -top-1/2 -left-70 w-1/2
  sm:-top-180 sm:-left-140
  lg:-top-310 lg:-left-380 lg:w-550
`;

const CasePosts = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "illustration-orange.png" }) {
        childImageSharp {
          fluid(maxWidth: 620) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [120, 0]);

  const opacityDelay = useTransform(scroll, [sectionStart + 480, sectionStart + 700], [0, 1]);
  const right = useTransform(scroll, [sectionStart + 480, sectionStart + 700], [-320, -205]);
  const bottom = useTransform(scroll, [sectionStart + 480, sectionStart + 700], [-400, -250]);

  const opacityIllustration = useTransform(scroll, [sectionStart + 400, sectionStart + 680], [0, 1]);
  const leftIllustration = useTransform(scroll, [sectionStart + 400, sectionStart + 680], [-120, 0]);

  const opacityDelay2 = useTransform(scroll, [sectionStart + 620, sectionStart + 800], [0, 1]);
  const leftDelay = useTransform(scroll, [sectionStart + 620, sectionStart + 800], [-450, -380]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <>
      <MockupWrapper ref={Ref} style={ width >= 1024 && { opacity, left }}>
        <Img fluid={data.right.asset.fluid} />
        <Mockup style={ width >= 1024 && { opacity: opacityDelay, right, bottom }}>
          <Img fluid={data.mockup.asset.fluid} />
        </Mockup>
      </MockupWrapper>
      <IllustrationWrapper style={ width >= 1024 && { opacity: opacityIllustration, left: leftIllustration }}>
        <Img fluid={data.left.asset.fluid} />
        <Illustration style={ width >= 1024 && { opacity: opacityDelay2, left: leftDelay }}>
          <Img fluid={illustration.childImageSharp.fluid} />
        </Illustration>
      </IllustrationWrapper>
    </>
  );
};

export default CasePosts;
