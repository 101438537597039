import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";
import { lang } from "@utils/helpers";

import ArrowPrimary from "@components/arrows/primary";
import DetailsLarge from "@components/svg/details-large";
import ArrowRed from "@components/arrows/red";

const Wrapper = tw.div`
  relative mt-26 ml-40 mr-30
  sm:flex sm:flex-wrap sm:mt-30
  lg:mt-225
`;

const Heading = styled(motion.div)`
  ${tw`
    mb-20 text-sm text-second leading-18 -tracking-021
    sm:self-center sm:order-2 sm:w-320 sm:mb-0 sm:ml-auto
    lg:relative lg:w-590 lg:ml-30 lg:text-primary lg:text-2xl lg:leading-52 lg:-tracking-024
    lg:opacity-0
    xl:w-640 xl:ml-110
  `}
  
  br {
    ${tw`hidden lg:block`}
  }
  
  span {
    ${tw`relative text-portfolioText whitespace-no-wrap`}
    
    &::after {
      content: '';
      ${tw`
        absolute top-15 left-0 right-0 block h-1 bg-portfolioText
        lg:top-33 lg:h-2
      `}
    }    
  }
`;

const Team = tw(Img)`w-full`;

const TeamWrapper = tw(motion.div)`
  relative max-w-320 mx-auto
  sm:order-1 sm:max-w-none sm:w-1/3 sm:mx-0
  lg:w-325 lg:opacity-0
`;

const DotsWrapper = styled.div`
  ${tw`
    absolute -bottom-90 -right-60 w-220 transform -rotate-45
    sm:-bottom-60 sm:-right-60 sm:w-200
    lg:-bottom-90 lg:-right-90 lg:w-260
  `}

  g:nth-child(1), g:nth-child(2), g:nth-child(5), g:nth-child(6) {
    ${tw`hidden`}
  }
`;

const DefaultArrow = styled.div`
  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const ArrowWrapper = tw(DefaultArrow)`
  absolute -bottom-30 -left-40 w-100 transform -scale-y-100
  lg:-bottom-45 lg:-left-50 lg:w-150
`;

const ArrowRedWrapper = tw(DefaultArrow)`
  hidden
  sm:block sm:absolute sm:top-130 sm:-right-80 sm:w-120
  lg:top-300 lg:-right-290 lg:w-300
`;

const CaseHeader = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const Ref = React.useRef();
  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 140, sectionStart + 650], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 140, sectionStart + 650], [120, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper>
      <Heading style={ width >= 1024 && { opacity, left }}>
        { data.text[lang] }
      </Heading>
      <TeamWrapper style={ width >= 1024 && { opacity }}>
        <Team fluid={ data.image.asset.fluid } />
        <ArrowWrapper>
          <ArrowPrimary />
        </ArrowWrapper>
        <DotsWrapper>
          <DetailsLarge />
        </DotsWrapper>
      </TeamWrapper>
      <ArrowRedWrapper>
        <ArrowRed />
      </ArrowRedWrapper>
    </Wrapper>
  );
};

export default CaseHeader;
