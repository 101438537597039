import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { lang } from "@utils/helpers";

import CaseSteps from "@components/cases/template/steps";
import ArrowSecondary from "@components/arrows/secondary";
import DetailsLarge from "@components/svg/details-large";

const Wrapper = tw.div`
  relative flex flex-col mx-40 mb-40
  lg:mx-0 lg:mb-140
`;

const Header = tw(motion.div)`
  mb-15 text-md -tracking-021
  md:self-end md:w-265 md:mb-40 md:text-xl
  lg:relative lg:w-440 lg:mb-55 lg:text-38 lg:leading-60 lg:-tracking-028 lg:opacity-0
  xl:w-520
`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden transform rotate-45
    md:block md:absolute md:-top-60 md:-left-110 md:w-100
    lg:-top-80 lg:-left-160 lg:w-120
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const DetailsWrapper = tw.div`
  hidden transform -scale-x-100
  md:block md:absolute md:-top-30 md:-right-260 md:w-200
  lg:-top-50 lg:-right-330 lg:w-300
`;

const CaseNaming = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart + 60, sectionStart + 450], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 60, sectionStart + 450], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 60, sectionStart + 450], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Header style={ width >= 1024 && { y, rotate, opacity }}>
      { data.text[lang].split('\n').map((line, index) => (
        <p key={index}>{line}</p>
      ))}
      </Header>
      <CaseSteps
        width={width}
        scroll={scroll}
        sectionStart={sectionStart}
        data={data.points}
      />
      <ArrowWrapper>
        <ArrowSecondary />
      </ArrowWrapper>
      <DetailsWrapper>
        <DetailsLarge />
      </DetailsWrapper>
    </Wrapper>
  );
};

export default CaseNaming;
