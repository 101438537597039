import React from "react";
import { motion, useTransform } from "framer-motion";
import tw from "twin.macro";
import { lang } from "@utils/helpers";

type StepsProps = {
  width: number, 
  scroll: any, 
  sectionStart: number,
  data: string[]
};

const StepsWrapper = tw.div`
  flex flex-col flex-wrap
  md:flex-row md:justify-between
`;

const StepWrapper = tw(motion.div)`
  flex items-baseline mb-8
  md:w-310 md:mb-30
  lg:w-340 lg:last:-mt-90
  xl:w-auto xl:max-w-520 xl:pr-80
`;

const Step = tw.div`
  max-w-350 text-second -tracking-021
  lg:max-w-none lg:text-md lg:leading-30 lg:-tracking-027
`;

const StepNumber = tw.span`
  font-pangramBold min-w-20 text-sm text-portfolioText
  lg:min-w-40 lg:mr-10 lg:text-lg lg:leading-32 lg:-tracking-03
`;

const CaseSteps = ({ width, scroll, sectionStart, data }: StepsProps) => {
  const x = [
    useTransform(scroll, [sectionStart + 170, sectionStart + 400], [150, 0]),
    useTransform(scroll, [sectionStart + 270, sectionStart + 500], [150, 0]),
    useTransform(scroll, [sectionStart + 370, sectionStart + 600], [150, 0]),
    useTransform(scroll, [sectionStart + 470, sectionStart + 700], [150, 0]),
    useTransform(scroll, [sectionStart + 570, sectionStart + 800], [150, 0]),
  ];

  const opacity = [
    useTransform(scroll, [sectionStart + 170, sectionStart + 400], [0, 1]),
    useTransform(scroll, [sectionStart + 270, sectionStart + 500], [0, 1]),
    useTransform(scroll, [sectionStart + 370, sectionStart + 600], [0, 1]),
    useTransform(scroll, [sectionStart + 470, sectionStart + 700], [0, 1]),
    useTransform(scroll, [sectionStart + 570, sectionStart + 800], [0, 1]),
  ];

  return (
    <StepsWrapper>
      { data.map((item, index) => (
        <StepWrapper 
          key={index} 
          style={ width >= 1024 && { 
            x: x[index], 
            opacity: opacity[index] 
          }}
        >
          <StepNumber>
            { index + 1 }
          </StepNumber>
          <Step>
            { item.text[lang].split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            )) }
          </Step>
        </StepWrapper>
      ))}
    </StepsWrapper>
  );
};

export default CaseSteps;
