import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";
import { lang } from "@utils/helpers";

import LayoutPortfolio from "@layouts/portfolio";
import SEO from "@components/seo";
import CaseHeader from "@components/cases/template/header";
import CaseLogo from "@components/cases/template/logo";
import CaseNaming from "@components/cases/template/naming";
import CaseVideo from "@components/cases/template/video";
import CaseOffer from "@components/cases/template/offer";
import CaseMockup from "@components/cases/template/mockup";
import CaseHeadings from "@components/cases/template/heading";
import CaseBackground from "@components/cases/template/background";
import CaseDescription from "@components/cases/template/description";
import CasePosts from "@components/cases/template/posts";

const CaseTemplate = ({ pageContext }) => {
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();
  
  const { allSanityCases } = useStaticQuery(graphql`
    query {
      allSanityCases {
        nodes {
          slug { current }
          title
          template {
            description {
              image { asset { fluid(maxWidth: 325) { ...GatsbySanityImageFluid } } }
              text { en, pl }
              seo { en, pl }
            }
            logo {
              image { asset { fluid { ...GatsbySanityImageFluid } } }
            }
            top {
              points { text { en, pl } }
              text { en, pl }
            }
            images {
              left { asset { fluid { ...GatsbySanityImageFluid } } }
              right {
                ... on SanityImage { _type, asset { fluid { ...GatsbySanityImageFluid } } }
                ... on SanityUrlObj { _type, id }
              }
            }
            bottom {
              text { en, pl }
              points { description { en, pl }, name { en, pl }  }
            }
            mockup {
              image { asset { fluid(maxWidth: 1200) { ...GatsbySanityImageFluid } } }
            }
            big { 
              text { en, pl } 
            }
            grid {
              image { asset { fluid(maxWidth: 1280) { ...GatsbySanityImageFluid } } }
            }
            small { 
              text { en, pl } 
            }
            posts {
              left { asset { fluid(maxWidth: 755) { ...GatsbySanityImageFluid } } }
              mockup { asset { fluid(maxWidth: 620) { ...GatsbySanityImageFluid } } }
              right { asset { fluid(maxWidth: 755) { ...GatsbySanityImageFluid } } }
            }
          }
        }
      }
    }
  `);
  const data = allSanityCases.nodes.find(item => item.slug.current === pageContext.case);
  const template = data.template;

  return (
    <LayoutPortfolio
      width={width}
      scroll={scrollY}
      name={data.slug.current}
    >
      <SEO
        lang={lang}
        title={`Case study ${data.title}`}
        description={template.description.seo[lang]}
      />
      <CaseHeader width={width} scroll={scrollY} data={template.description} />
      <CaseLogo width={width} scroll={scrollY} data={template.logo} />
      <CaseNaming width={width} scroll={scrollY} data={template.top}/>
      <CaseVideo width={width} scroll={scrollY} data={template.images}/>
      <CaseOffer width={width} scroll={scrollY} data={template.bottom}/>
      <CaseMockup width={width} scroll={scrollY} data={template.mockup}/>
      <CaseHeadings width={width} scroll={scrollY} data={template.big}/>
      <CaseBackground width={width} scroll={scrollY} data={template.grid}/>
      <CaseDescription width={width} scroll={scrollY} data={template.small}/>
      <CasePosts width={width} scroll={scrollY} data={template.posts}/>
    </LayoutPortfolio>
  );
};

export default CaseTemplate;
