import React from "react";

const Letters = () => (
  <svg viewBox="0 0 429 200">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M188.857433,109.939745 C193.600472,110.125468 186.57159,96.1825161 195.711535,92.1688444 C204.85148,88.1586121 218.559684,99.6218234 222.555626,95.6081517 C226.554994,91.5979194 216.846159,73.8270185 237.408465,72.1073648 C257.970771,70.3877112 265.966081,89.8782658 270.53434,91.0235551 C275.106027,92.1688444 276.819552,78.879361 293.954807,90.9685262 C311.090063,103.061131 297.381858,112.804688 300.236592,117.389285 C303.091325,121.977321 323.084741,113.949978 331.652369,131.146514 C340.219996,148.343051 333.280218,186.732599 299.880179,192.555346 C269.036719,197.927544 266.250527,184.386992 262.823476,184.816905 C258.371736,185.374073 265.822145,201.153615 240.263199,199.932661 C222.565907,199.09003 208.278531,178.151527 203.137955,177.006238 C197.997378,175.857509 197.425061,183.884853 186.002699,183.310488 C174.576911,182.736124 157.441656,152.2673 158.013974,135.971862 C158.586291,119.683303 163.438995,108.935468 188.857433,109.939745" id="Fill-4" fill="#EBEDF6"></path>
      <g transform="translate(267.000000, 0.000000)">
        <path d="M29.445175,11.5990788 C27.5933417,16.1944727 26.1310083,8.40198788 16.8547583,9.9462303 C7.581925,11.4904727 -3.764825,28.5925333 1.688175,38.7845333 C8.289175,51.124897 18.2180083,46.8858667 21.0982583,51.9564121 C23.9785083,57.0269576 16.909425,60.7025939 28.2185917,66.5367758 C39.5243417,72.3675636 50.498675,60.4785939 55.6920083,66.3161697 C60.881925,72.1503515 71.4735917,74.6618667 82.181425,67.6058667 C86.7460917,64.5954424 87.654925,58.2284121 91.8710917,57.8720485 C98.2875917,57.3290182 103.429675,54.5425939 105.500175,47.3847758 C111.899592,25.249503 96.5895083,15.0642909 90.6240083,13.7338667 C84.6585083,12.4034424 84.3612583,15.6548364 79.6565083,11.4938667 C76.369675,8.58865455 73.161425,7.13265455 67.7255083,7.35665455 C60.8272583,7.63835152 61.691675,13.7270788 56.269425,11.2902303 C51.0795083,8.9552 44.5980917,-2.18710303 37.194175,0.850472727 C31.433675,3.20926061 31.4985917,6.5081697 29.445175,11.5990788" id="Fill-6" fill="#EBEDF6" mask="url(#mask-2)"></path>
      </g>
      <g transform="translate(0.000000, 12.000000)">
        <polygon id="Fill-1" fill="#F59386" points="267 37.0883799 269.394389 56 314 53.7486785 306.383389 28" />
        <polyline id="Stroke-2" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" points="267 36.3171835 286.501293 47 306 27" />
        <line x1="269" y1="56" x2="279" y2="44" id="Stroke-3" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="293" y1="41" x2="314" y2="54" id="Stroke-4" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <polygon id="Fill-5" fill="#F59386" points="386 99.6856315 425.777258 108 429 86.8791766 386 83" />
        <polyline id="Stroke-6" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" points="429 87.0339264 402.984819 96 386 83" />
        <line x1="426" y1="109" x2="409" y2="94" id="Stroke-8" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="398" y1="92" x2="386" y2="100" id="Stroke-9" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <polygon id="Fill-10" fill="#F59386" points="151 64.5 150.344018 77 114 70.6969791 115.904971 52" />
        <polyline id="Stroke-11" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" points="116 51 128.408972 62.656 134.100561 68 151 63.888" />
        <line x1="114" y1="70" x2="128" y2="63" id="Stroke-12" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="150" y1="77" x2="140" y2="67" id="Stroke-13" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <path d="M338.294395,38.5581154 C338.664115,40.1755192 337.880413,42.6885962 333.295371,42.6885962 C330.465194,42.6885962 328.475999,42.0473462 328.168767,40.4169615 C327.84331,38.6879231 330.246487,36.6836923 333.256316,36.30725 C336.068267,35.9541731 337.927279,36.9433077 338.294395,38.5581154 Z" id="Stroke-14" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="343.356688" y1="36.2358558" x2="380.026615" y2="31.6536442" id="Stroke-16" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="344.442414" y1="17.1600962" x2="404.974871" y2="0.285096154" id="Stroke-18" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="336.196627" y1="58.0495192" x2="387.186676" y2="64.9734615" id="Stroke-20" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="338.373026" y1="79.6832692" x2="369.827824" y2="88.9878846" id="Stroke-22" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="329.796835" y1="8.72259615" x2="347.37153" y2="0.285096154" id="Stroke-24" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="388.859787" y1="110.401221" x2="425.183427" y2="120.996125" id="Stroke-26" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="20.2869262" y1="17.1600962" x2="83.6417494" y2="37.7294231" id="Stroke-28" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="100.962327" y1="19.9717308" x2="137.452602" y2="36.2366346" id="Stroke-30" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="35.2579628" y1="49.8960385" x2="69.7564384" y2="55.9736346" id="Stroke-32" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="95.4498616" y1="17.1600962" x2="86.3552823" y2="12.8323077" id="Stroke-34" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="19.5165037" y1="75.9920577" x2="54.9913512" y2="78.4168654" id="Stroke-36" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="61.8259549" y1="78.4158269" x2="109.34012" y2="81.0119808" id="Stroke-38" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="20.912325" y1="133.685346" x2="63.7841665" y2="122.444" id="Stroke-40" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <path d="M17.6832677,42.7971154 C17.6832677,44.7052885 15.454536,47.4883654 14.1032372,48.7475 C12.7311091,50.0248077 10.7367067,51.5305769 8.63034695,51.5305769 C4.45407866,51.5305769 0.32467622,48.91625 0.32467622,45.06875 C0.32467622,42.0390385 2.03007256,41.3069231 3.14704207,40.0919231 C4.53479207,38.5835577 7.22957866,38.3628846 9.54683476,38.3628846 C13.7257067,38.3628846 17.6832677,38.9496154 17.6832677,42.7971154 Z" id="Stroke-42" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <polygon id="Fill-44" fill="#EE3F2E" points="292.801451 69.709625 305.73903 80.9587596 311.589451 68.6166442" />
        <line x1="314.307149" y1="78.5020192" x2="302.439674" y2="74.4364423" id="Stroke-46" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <line x1="320.259373" y1="76.0730577" x2="305.991324" y2="71.7997885" id="Stroke-48" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
        <polygon id="Fill-50" fill="#00A1D6" points="66.9189713 115.697375 70.6942762 126.323433 85.1862396 116.99026" />
      </g>
    </g>
  </svg>
);

export default Letters;
