import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import tw from "twin.macro";
import Img from "gatsby-image";

const Background = tw(motion.div)`w-full mb-40 lg:relative lg:mb-170`;

const CaseBackground = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const top = useTransform(scroll, [sectionStart + 80, sectionStart + 400], [100, 0]);
  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Background ref={Ref} style={ width >= 1024 && { top, opacity }}>
      <Img fluid={ data.image.asset.fluid } />
    </Background>
  );
};

export default CaseBackground;
