import React from "react";
import { PortfolioTemplateItem } from "@utils/types";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { lang } from "@utils/helpers";

const Heading = tw(motion.div)`
  mx-40 mb-40 text-sm text-second leading-18 -tracking-021
  sm:self-center sm:order-2 sm:w-320 sm:mb-60 sm:ml-auto
  lg:relative lg:w-590 lg:ml-330 lg:mb-150 lg:text-primary lg:text-icon lg:leading-30
  lg:-tracking-024
  xl:w-680 xl:ml-440 xl:text-2xl xl:leading-52
`;

const CaseHeadings = ({ width, scroll, data }: PortfolioTemplateItem) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart + 60, sectionStart + 450], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 60, sectionStart + 450], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 60, sectionStart + 450], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Heading ref={Ref} style={ width >= 1024 && { y, rotate, opacity }}>
      { data.text[lang] }
    </Heading>
  );
};

export default CaseHeadings;
